import httpService from '../helpers/http.service.js';

export default {
    saveForm(params) {
        return httpService.post(`guest/orders`, params);
    },
    getDistrict(params) {
        return httpService.get(`guest/districts`, {params});
    },
    getState() {
        return httpService.get(`guest/states`);
    },
    getDistrictByState(state){
        return httpService.get(`guest/states/${state}/districts`);
    }
};

