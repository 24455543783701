<template>
	<form-wizard @on-complete="onComplete" shape="tab" color="#20B2AA">
		<h2 slot="title"></h2>
        <tab-content class="mb-sm-5" title="School" icon="fa fa-graduation-cap">
			<div class="md-layout-item px-lg-5">
				<div class="content-text">
                    <div class="row mx-lg-5">
                        <div class="col-md-12">
                            <h4 class="text-left">
                                <span class="content-text-bold">
                                    SOS (Safely Opening Schools)
                                </span>
                                is a program to keep schools across the United States open,
                                safe and available to every student and family.
                                Our program will help your school district access the capital
                                required to meet the health care needs for all schools
                                in your district.
                                <br />
                                <br />
                                <p class="content-text-bold">
                                    Let's begin preparing your submission for your Districts
                                    ESSER Funding Application
                                </p>
                            </h4>
                        </div>
                    </div>
				</div>
                <div class="grid">
                    <div class="row mx-lg-5">
                        <div class="col-md-4">
                            <div class="form-label">
                                STATE
                            </div>
                        </div>
                        <div class="col-md-8">
                            <md-field>
                                <span class="required">*</span>
                                <md-select v-model="state_id" placeholder="State" @change="getDistrictsByState">
                                    <md-option
                                        v-for="state of states"
                                        :value="state.id"
                                        :key="state.id"
                                    >
                                        {{ state.name }}
                                    </md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                    <div class="row mx-lg-5">
                            <div class="col-md-4">
                                <div class="form-label">
                                    DISTRICT
                                </div>
                            </div>
                            <div class="col-md-8">
                                <md-field>
                                    <span class="required">*</span>
                                    <md-select v-model="value.district_id" placeholder="District">
                                        <md-option
                                            v-for="district of districts"
                                            :value="district.id"
                                            :key="district.id"
                                        >
                                            {{ district.name }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>
                    <div class="row mx-lg-5">
                            <div class="col-md-4">
                                <div class="form-label">
                                    YOUR SCHOOL
                                </div>
                            </div>
                            <div class="col-md-8">
                                <md-field>
                                    <span class="required">*</span>
                                    <md-input
                                        v-model="value.your_school_name"
                                        placeholder="Your school"
                                        @blur="$v.value.your_school_name.$touch()" required
                                    />
                                </md-field>
                                <p v-if="$v.value.your_school_name.$error" :class="{ error: $v.value.your_school_name.$error }">
                                    Please enter a valid school name.
                                </p>
                            </div>
                        </div>
                    <div class="row mx-lg-5">
                        <div class="col-md-4">
                            <div class="form-label">
                                SCHOOL ADDRESS
                            </div>
                        </div>
                        <div class="col-md-8">
                                <md-field>
                                    <span class="required">*</span>
                                    <md-input
                                        v-model="value.school_address"
                                        placeholder="School address"
                                        @blur="$v.value.school_address.$touch()"
                                    />
                                </md-field>
                                <p v-if="$v.value.school_address.$error" :class="{ error: $v.value.school_address.$error }">
                                    Please enter a valid school address.
                                </p>
                        </div>
                    </div>
                    <div class="row mx-lg-5">
                        <div class="col-md-4">
                            <div class="form-label">
                                YOUR NAME OR PRIMARY CONTACT NAME
                            </div>
                        </div>
                        <div class="col-md-8">
                            <md-field>
                                <span class="required">*</span>
                                <md-input
                                    v-model="value.created_by_name"
                                    placeholder="Your name or primary contact name"
                                    @blur="$v.value.created_by_name.$touch()"
                                />
                            </md-field>
                            <p v-if="$v.value.created_by_name.$error" :class="{ error: $v.value.created_by_name.$error }">
                                Please enter a valid primary contact name.
                            </p>
                        </div>
                        </div>
                    <div class="row mx-lg-5">
                        <div class="col-md-4">
                            <div class="form-label">
                                PHONE NUMBER
                            </div>
                        </div>
                        <div class="col-md-8">
                            <md-field>
                                <span class="required">*</span>
                                <md-input
                                    v-model="value.created_by_phone"
                                    placeholder="Phone number"
                                    @blur="$v.value.created_by_phone.$touch()"
                                />
                            </md-field>
                            <p v-if="$v.value.created_by_phone.$error" :class="{ error: $v.value.created_by_phone.$error }">
                                Please enter a valid phone number.
                            </p>
                        </div>
                    </div>
                    <div class="row mx-lg-5">
                        <div class="col-md-4">
                            <div class="form-label">
                                EMAIL
                            </div>
                        </div>
                        <div class="col-md-8">
                            <md-field>
                                <span class="required">*</span>
                                <md-input
                                    v-model="value.created_by_email"
                                    placeholder="Email"
                                    @blur="$v.value.created_by_email.$touch()"
                                />
                            </md-field>
                            <p v-if="$v.value.created_by_email.$error" :class="{ error: $v.value.created_by_email.$error }">
                                Please enter a valid email.
                            </p>
                        </div>
                    </div>
            </div>
			</div>
            <div class="subscribe-line subscribe-line-image m-sm-5">
                <div class="container justify-content-center">
                    <div class="md-layout">
                        <div class="row text-center">
                            <div class="col-sm-12 col-md-3">
                                <img
                                    src="../../assets/img/vendors/draganfly_logo.png"
                                    alt="Rounded Image"
                                    class=""
                                    style="width: 250px;"
                                >
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <img
                                    src="../../assets/img/vendors/pure_virus_logo.png"
                                    alt="Rounded Image"
                                    class="rounded"
                                    style="width: 250px;"
                                />
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <img
                                    src="../../assets/img/vendors/BioFlyte_logo_gradient_green-1-2048x756.png"
                                    alt="Rounded Image"
                                    class="rounded"
                                    style="width: 250px;"
                                >
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <img
                                    src="../../assets/img/vendors/varigard_logo.png"
                                    alt="Rounded Image"
                                    class="rounded"
                                    style="width: 250px;"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <div class="container justify-content-center">
                    <div class="md-layout">
                        <div class="row text-center">
                            <div class="col-sm-12 col-md-3">
                                <img
                                    src="../../assets/img/vendors/Coldchain_Logo.png"
                                    alt="Rounded Image"
                                    class="rounded"
                                    style="width: 200px;"
                                />
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <img
                                    src="../../assets/img/vendors/Health_Hero_Logo.png"
                                    alt="Rounded Image"
                                    class="rounded"
                                    style="width: 350px;"
                                />
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <img
                                    src="../../assets/img/vendors/Hueman_Logo_green.png"
                                    alt="Rounded Image"
                                    class="rounded"
                                    style="width: 350px;"
                                />
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <img
                                    src="../../assets/img/vendors/kaleo-logo.jpg"
                                    alt="Rounded Image"
                                    class="rounded"
                                    style="width: 200px;"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</tab-content>
		<tab-content class="mb-sm-5" title="Basic" icon="fa fa-database">
			<div class="md-layout-item px-lg-5">
                <div class="content-text">
                    <div class="row mx-lg-5">
                        <div class="col-md-12">
                            <h4 class="text-left">
                                <span class="content-text-bold">Basic information</span>
                            </h4>
                        </div>
                    </div>
                </div>
				<div class="content-text">
                    <div class="row mx-lg-5">
                        <div class="col-md-12">
                            <h4 class="text-left">
                                <h4>
                                    Please complete the form below. The information will
                                    be used to prepare your SOS Program proposal.
                                    You will be contacted to confirm the information
                                    provided and final pricing. At that time, an SOS Team
                                    Member will answer any questions you may have prior to
                                    developing a final SOS Program for your school or district.
                                    <br />
                                    <br />
                                    This is not a binding sales agreement.
                                </h4>
                            </h4>
                        </div>
                    </div>
				</div>
				<div class="d-grid">
					<div class="row mx-lg-5">
						<div class="col-md-4">
                            <div class="form-label">
							    FEE & REDUCED LUNCH RATE
                            </div>
						</div>
						<div class="col-md-8">
							<md-field>
								<span class="required">*</span>
                                <span class="md-prefix">%</span>
								<md-input
                                    type="number"
									v-model="value.free_rate_and_reduce_lunch_rate"
									placeholder="%"
									@blur="$v.value.free_rate_and_reduce_lunch_rate.$touch()"
								/>
							</md-field>
							<p v-if="$v.value.free_rate_and_reduce_lunch_rate.$error" :class="{ error: $v.value.free_rate_and_reduce_lunch_rate.$error }">
								Please enter a valid free rate.
							</p>
						</div>
					</div>
					<div class="row mx-lg-5">
						<div class="col-md-4">
                            <div class="form-label">
							    NUMBER OF STUDENTS
                            </div>
						</div>
						<div class="col-md-8">
							<md-field>
								<span class="required">*</span>
								<md-input
                                    type="number"
									v-model="value.number_students"
									placeholder="Number"
									@blur="$v.value.number_students.$touch()"
								/>
							</md-field>
							<p v-if="$v.value.number_students.$error" :class="{ error: $v.value.number_students.$error }">
								Please enter a valid number.
							</p>
						</div>
					</div>
					<div class="row mx-lg-5">
						<div class="col-md-4">
                            <div class="form-label">
							    CURRENT FULL-TIME NURSES
                            </div>
						</div>
						<div class="col-md-8">
							<md-field>
								<span class="required">*</span>
								<md-input
                                    type="number"
									v-model="value.current_fulltime_nurses"
									placeholder="Number"
									@blur="$v.value.current_fulltime_nurses.$touch()"
								/>
							</md-field>
							<p v-if="$v.value.current_fulltime_nurses.$error" :class="{ error: $v.value.current_fulltime_nurses.$error }">
								Please enter a valid number.
							</p>
						</div>
					</div>
					<div class="row mx-lg-5">
						<div class="col-md-4">
                            <div class="form-label">
							    EXISTING NURSES OFFICES
                            </div>
						</div>
						<div class="col-md-8">
							<md-field>
								<span class="required">*</span>
								<md-input
                                    type="number"
									v-model="value.existing_nurses_offices"
									placeholder="Number"
									@blur="$v.value.existing_nurses_offices.$touch()"
								/>
							</md-field>
							<p v-if="$v.value.existing_nurses_offices.$error" :class="{ error: $v.value.existing_nurses_offices.$error }">
								Please enter a valid number.
							</p>
						</div>
					</div>
                    <div class="row mx-lg-5">
                        <div class="col-md-4">
                            <div class="form-label">
                                OFFICES REQUIRING MODIFICATION
                            </div>
                        </div>
                        <div class="col-md-8">
                            <md-field>
                                <span class="required">*</span>
                                <md-input
                                    type="number"
                                    v-model="value.offices_required_notification"
                                    placeholder="Number"
                                    @blur="$v.value.offices_required_notification.$touch()"
                                />
                            </md-field>
                            <p v-if="$v.value.offices_required_notification.$error" :class="{ error: $v.value.offices_required_notification.$error }">
                                Please enter a valid number.
                            </p>
                        </div>
                    </div>

                    <div class="row mt-5 mx-lg-2">
                        <div clss="col-md-12">
                            <div class="row mx-lg-4">
                                <div class="col-sm-12 col-md-7 text-left" style="padding-left:22px;">
                                    <h4 class="text-left">
                                        MASS SANITIZATION PROGRAM
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row mx-lg-4">
                                <div class="col-sm-12 col-md-7 text-left" style="padding-left:22px;">
                                    <h6>
                                        Industrial Spray Application: Varigard &
                                        Draganfly Inc.
                                    </h6>
                                    <p>
                                        The application of the sanitization solution for large
                                        event areas are done via drone spraying application
                                        provided through Draganfly Inc and sanitization product
                                        is supplied by Varigard. A Draganfly Inc customer
                                        associate will contact you via email regarding application
                                        cost.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-lg-5">
                        <div class="col-md-4">
                            <div class="form-label">
                                NUMBER OF STADIUM OR EVENT AREAS
                            </div>
                        </div>
                        <div class="col-md-8">
                            <md-field>
                                <span class="required">*</span>
                                <md-input
                                    type="number"
                                    v-model="value.stadiums_or_events_areas"
                                    placeholder="Number"
                                    @blur="$v.value.stadiums_or_events_areas.$touch()"
                                />
                            </md-field>
                            <p v-if="$v.value.free_rate_and_reduce_lunch_rate.$error" :class="{ error: $v.value.free_rate_and_reduce_lunch_rate.$error }">
                                Please enter a valid free rate.
                            </p>
                        </div>
                    </div>
                    <div class="row mx-lg-5">
                        <div class="col-md-4">
                            <div class="form-label">
                                EST NUMBERS OF SEATS (ALL)
                            </div>
                        </div>
                        <div class="col-md-8">
                            <md-field>
                                <span class="required">*</span>
                                <md-input
                                    type="number"
                                    v-model="value.numbers_of_seats"
                                    placeholder="Number"
                                    @blur="$v.value.numbers_of_seats.$touch()"
                                />
                            </md-field>
                            <p v-if="$v.value.numbers_of_seats.$error" :class="{ error: $v.value.numbers_of_seats.$error }">
                                Please enter a valid number.
                            </p>
                        </div>
                    </div>
                    <div class="row mx-lg-5">
                        <div class="col-md-4">
                            <div class="form-label">
                                EST NUMBER OF EVENTS per year
                            </div>
                        </div>
                        <div class="col-md-8">
                            <md-field>
                                <span class="required">*</span>
                                <md-input
                                    type="number"
                                    v-model="value.numbers_of_events_per_year"
                                    placeholder="Number"
                                    @blur="$v.value.numbers_of_events_per_year.$touch()"
                                />
                            </md-field>
                            <p v-if="$v.value.numbers_of_events_per_year.$error" :class="{ error: $v.value.numbers_of_events_per_year.$error }">
                                Please enter a valid number.
                            </p>
                        </div>
                    </div>
				</div>
			</div>
		</tab-content>
		<tab-content class="mb-sm-5" title="Nurse" icon="fa fa-hospital">
            <div class="md-layout-item px-lg-5">
                <div class="content-text">
                    <div class="row mx-lg-5">
                        <div class="col-md-11">
                            <h4 class="text-left">
                                <span class="content-text-bold">Nurse</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="d-grid">
                    <div class="row mx-lg-5">
                        <div class="col-md-8">
                            <div class="d-grid">
                                <div class="row">
                                    <div class="col-md-5">
										<div class="form-label">
											NEW FULL-TIME NURSES REQUIRED
										</div>
                                    </div>
                                    <div class="col-md-6">
                                        <md-field>
                                            <md-input
                                                type="number"
                                                v-model="value.details[21].quantity"
                                                placeholder="Number"
                                            />
                                        </md-field>
                                    </div>
                                </div>
                            </div>
                            <div class="d-grid" style="margin-top: 20px; padding-left:12px;">
                                <h6>School nursing</h6>
                                <p>
                                    The Center for Disease Control (CDC) recognizes
                                    school nurses play a pivotal role in keeping
                                    schools open safely through: routine evaluation
                                    of students for symptoms/exposures, assisting
                                    teachers and administrators in implementing
                                    mitigation plans, managing student information
                                    regarding potential viral outbreaks, maintaining
                                    school-based health offices, managing testing
                                    programs (if offered), and providing additional
                                    support to students, staff, and families.
                                </p>
                                <p>
                                    A Registered Nurse (RN) or Licensed Practical
                                    Nurse (LPN) is the only feasible option to
                                    quickly identify, isolate, and initiate
                                    testing protocols for influenza-like or COVID-like
                                    illnesses. Antigen and antibody tests may also
                                    be administered by the school nurse. Finally,
                                    test results can be monitored by the school
                                    nurse and shared with appropriate school
                                    administrators.
                                </p>
                            </div>
                            <br />
                            <div class="d-grid">
                                <div class="row">
                                    <div class="col-md-12">
										<div style="padding-left:11px;">
                                        	Does your school require the following?
                                        	<br/>
                                        	Please check each option your require.
										</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
										<div class="form-label">
											School Located Vaccination Clinics
										</div>
                                    </div>
                                    <div class="col-md-6" style="padding-top: 8px">
                                        <md-checkbox value="1" v-model="value.details[26].quantity" product_id="26"></md-checkbox>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
										<div class="form-label">
											School Located Sentinel Testing
										</div>
                                    </div>
                                    <div class="col-md-6" style="padding-top: 8px">
                                        <md-checkbox value="1" v-model="value.details[27].quantity" product_id="27"></md-checkbox>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
										<div class="form-label">
											School Located Symptomatic Testing
										</div>
                                    </div>
                                    <div class="col-md-6" style="padding-top: 8px">
                                        <md-checkbox value="1" v-model="value.details[32].quantity" product_id="32"></md-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-5">
                            <img
                                src="https://media.nurse.org/cache/93/31/93318de9c43d08330b0333b4c61d6a79@2x.jpg"
                                alt=""
                                style="border-radius: 9%"
                            />
                        </div>
                    </div>
                </div>
            </div>
		</tab-content>
        <tab-content class="mb-sm-5" title="Nurse Office" icon="fa fa-hospital">
            <div class="md-layout-item px-lg-5">
                <div class="content-text">
                    <div class="row m-5">
                        <div class="col-md-12">
                            <h4 class="text-left">
                                <span class="content-text-bold">Nurse Offices</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="d-grid">
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-12 col-md-7" style="padding-left:22px;">
                                    <h6 class="mb-md-1 mt-md-3">
                                        SCHOOLS REQUIRING A NURSE OFFICE - Move to above the image
                                    </h6>
                                    <p>
                                        The IQ Med Pod is a stand-alone structure designed
                                        to provide dual isolation protection for students,
                                        faculty, and visitors. The child-friendly isolation
                                        room separates potentially contagious students from
                                        others in the building. With built-in advanced ventilation
                                        and virus control systems, it offers a second layer of
                                        protection byreducing the spread of airborne viruses.
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/IQ_med_pod.jpg"
                                        width="250"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">IQ MED POD</div>
                                        </div>
                                    </div>
                                    <div class="row m-1">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[23].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-lg-5">
                        <h6 class="mt-5" style="padding-left:22px;">
                            MEDICAL EQUIPMENT REQUIREMENTS
                        </h6>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/G1_fidge_image.png"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                G1 VACCINE FRIDGE & 24/7 MONITORING
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[24].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-3 text-center">
                                    <p>
                                        14.125”W x 16”D x 19.75”H
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/G2_fidge_image.png"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                G2 VACCINE FRIDGE & 24/7 MONITORING
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[31].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-3 text-center">
                                    <p>
                                        17”W x 19.5”D x 26”H
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row m-lg-2">
                        <div class="col-sm-12 col-md-9" style="padding-left:22px;">
                            <h6 class="">
                                AIR PURIFICATION SYSTEMS
                            </h6>
                            <p>
                                Each of the three technologies eliminate over 99% of airborne
                                SARS-CoV-2 (COVID-19) virus in third party lab tests.
                                The COUNTERTOP and WALL MOUNT devices both come with cords
                                for fast and easy "plug-in" to any standard wall electrical outlet.
                                The CEILING FIXTURE has the added advantage of illumination,
                                but it does require maintenance staff or an electrician to
                                wire the fixture into standard drop ceilings. Each of these
                                devices are also applicable for classrooms and administrative
                                offices across other areas of schools. Technical and Operational
                                Support: The Purge Virus team is available for product support
                                as well as guidance and proposals for areas of schools beyond
                                nurse offices, including in-duct technologies for heating
                                ventilation and air conditioning (HVAC) systems.
                            </p>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/vollara_product.png"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                Vollara: Countertop Disinfection Unit
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[28].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-3 text-center">
                                    <p>
                                        Plug & Play Device
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/uv_image.png"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                VC Wall Mount Air Disinfecting Unit
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[29].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-3 text-center">
                                    <p>
                                        Plug & Play Device
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/uvc_troffers.png"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
											<div class="form-label">
                                            	UVC Ceiling Fixture Disinfection
											</div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[30].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-3 text-center">
                                    <p>
                                        Installation Required
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-lg-5">
                        <h6 class="mt-5" style="padding-left:22px;">
                            MEDICAL PRODUCTS REQUIREMENTS
                        </h6>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/auvi-q_33Ilbs_or_less.png"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                AUVI-Q 0.1 mg–for infants & toddlers 16.5-33 lbs
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[18].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/auvi_q_33_to_66Ilbs.png"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                AUVI-Q 0.15 mg–for children 33-66 lbs
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[19].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/auvi-q 66Ilbs_or_more.png"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                AUVI-Q 0.3 mg–for children and adults 66 lbs and up
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[20].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12" style="padding-left:22px;">
                                    <p>
                                        AUVI-Q® (epinephrine injection, USP) is a prescription
                                        medicine used to treat life-threatening allergic reactions,
                                        including anaphylaxis, in people who are at risk for or
                                        have a history of serious allergic reactions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tab-content>
        <tab-content class="mb-sm-5" title="Facilities" icon="fa fa-mobile">
            <div class="md-layout-item px-lg-5">
                <div class="content-text">
                    <div class="row m-5">
                        <div class="col-md-12">
                            <h4 class="text-left">
                                <p class="content-text-bold">School Facilities</p>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="d-grid">
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/point_of_entry.jpg"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                POINT OF ENTRY SCREENING KIOSK
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-1">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[2].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-7 text-left" style="padding-left:22px;">
                                    <h6 class="mb-md-1 mt-md-3">
                                        DRAGANFLY SMART VITAL KIOSK
                                    </h6>
                                    <p>
                                        An enterprise-level screening kiosk that
                                        provides fast, easy and accurate assessment
                                        of an individual’s temperature and vital signs.
                                        Calibrated against CDC guidelines, the Point
                                        of Entry Kiosk provides Staff & Nurses with
                                        a real-time alert of individuals that may
                                        require isolation or testing.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/social_device.jpg"
                                        width="250"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                SOCIAL DISTANCING KIOSK
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-1">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[3].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-7 text-left" style="padding-left:22px;">
                                    <h6 class="mb-md-1 mt-md-3">
                                        DRAGANFLY SOCIAL DISTANCE KIOSK
                                    </h6>
                                    <p>
                                        Automated monitoring of social distancing protocol.
                                        Includes innovative display capabilities that
                                        provide students & staff with real-time feedback
                                        on social distancing. Also provides valuable
                                        reporting and insight on program effectiveness,
                                        enabling school administrators to continuously
                                        improve social distancing communications and
                                        processes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/z720_Biological_Air_Sampler_System.jpg"
                                        width="250"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                BIOAEROSOL COLLECTOR
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-1">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[16].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/BioFlyte_logo_gradient_green-1-2048x756.png"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                BIOAE 360 Testing Kits for z720
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-1">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[17].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-7 text-left" style="padding-left:22px;">
                                    <h6 class="mb-md-1 mt-md-3">
                                        BioCapture™ z720
                                    </h6>
                                    <p>
                                        The BioCapture bioaerosol collector is the most advanced, quiet,
                                        handheld, and lightweight bioaerosol collection system in the world.
                                        It is proven to collect SARS-CoV-2 (coronavirus) and other pathogens
                                        directly from air at the lowest limit of detection (LOD) achievable
                                        with PCR or other analytical testing. Students and faculty will be
                                        proactively protected with early airborne virus detection. In classrooms,
                                        locker rooms, band or choir rooms, auditoriums, or even on the bus,
                                        BioCapture will quickly detect hazardous pathogens. The BioFlyte
                                        technology is used world-wide. It’s in the Air - find it with
                                        BioFlyte.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tab-content>
        <tab-content class="mb-sm-5" title="Sanitization" icon="fa fa-leaf">
            <div class="md-layout-item px-lg-5">
                <div class="content-text">
                    <div class="row m-5">
                        <div class="col-sm-12 text-left">
                            <h4 class="text-left">
                                <span class="content-text-bold">Sanitization</span>
                            </h4>
                        </div>
                    </div>
                    <div class="row mx-lg-5">
                        <div class="col-sm-12 text-left" style="padding-left:22px;">
                            <h4 class="text-left">
                                Mass Sanitization, Outbreak Management & Air Purification
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="d-grid">
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/v_card_box.jpg"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                V-Card 150 Units per Box
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-1">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[6].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-7 text-left" style="padding-left:22px;">
                                    <p>
                                        The Varigard V-Card's contain 1.5 ml of our
                                        protective hands sanitizing lotion. It is the
                                        perfect amount to cover your hands when you're on
                                        the go. Each box comes with 150 individually
                                        packaged cards that are ideal for high-traffic,
                                        public areas where it's important to hinder the
                                        spread of pathogenic disease, including SARS-CoV-2
                                        (COVID-19).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/Sidebyside.jpg"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                Hand Sanitizer 2 oz (24 Pack)
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-1">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[7].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/Sidebyside.jpg"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                Hand Sanitizer 8 oz (24 Pack)
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-1">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[8].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/Multi-item_image.jpg"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                Hand Sanitizer 16 oz (24 Pack)
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-1">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[9].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/Multi-item_image.jpg"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                Hand Sanitizer 1 Gallon (4 Pack)
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-1">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[10].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-7 text-left" style="padding-left:22px;">
                                    <p>
                                        Varigard's organic, patented polymers work in
                                        combination with alcohol, extending the kill-time
                                        of our hand sanitizer from 30 seconds to two hours.
                                        The flame-retardant layer of organic polymer keeps
                                        chemicals off human skin while stopping them from
                                        evaporating, so they keep killing the germs you
                                        touch for 2 hours
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 mx-lg-5">
                        <div class="col-sm-12 col-md-7 text-left" style="padding-left:22px;">
                            <h4 class="text-left">
                                AIR PURIFICATION FOR BUSES
                            </h4>
                        </div>
                    </div>
                    <div class="row m-lg-2">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-sm-3 col-lg-3 text-center">
                                    <img
                                        src="../../assets/img/vendors/vollara_product.png"
                                        width="150"
                                        class="img-fluid rounded shadow-lg m-3"
                                    />
                                </div>
                                <div class="col-sm-9 col-lg-4">
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div class="form-label">
                                                BUS IONIZATION DEVICE 100 - 500 sq ft
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-1">
                                        <div class="col-md-12">
                                            <md-field>
                                                <md-input
                                                    type="number"
                                                    v-model="value.details[15].quantity"
                                                    placeholder="Number"
                                                />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tab-content>
	</form-wizard>
</template>
<script>
import service from "../../services/request.service";
import { required } from 'vuelidate/lib/validators'
import router from "@/router";

export default {
	name: "MainForm",
	props: {},
	data: () => {
		return {
            state_id:0,
			value: {
                district_id: 0,
                state_id: 0,
                your_school_name: '',
                school_address: '',
                created_by_name: '',
                created_by_phone: '',
                created_by_email: '',
                free_rate_and_reduce_lunch_rate: '',
                number_students: '',
                current_fulltime_nurses: '',
                existing_nurses_offices: '',
                offices_required_notification: '',
                stadiums_or_events_areas: '',
                numbers_of_seats: '',
                numbers_of_events_per_year: '',
                order_price: 0,
                grant: 0,
				details: {
                    1: {
                        product_id: 1,
                        quantity: ""
                    },
                    2: {
                        product_id: 2,
                        quantity: ""
                    },
                    3: {
                        product_id: 3,
                        quantity: ""
                    },
                    4: {
                        product_id: 4,
                        quantity: ""
                    },
                    5: {
                        product_id: 5,
                        quantity: ""
                    },
                    6: {
                        product_id: 6,
                        quantity: ""
                    },
                    7: {
                        product_id: 7,
                        quantity: ""
                    },
                    8: {
                        product_id: 8,
                        quantity: ""
                    },
                    9: {
                        product_id: 9,
                        quantity: ""
                    },
                    10: {
                        product_id: 10,
                        quantity: ""
                    },
                    11: {
                        product_id: 11,
                        quantity: ""
                    },
                    12: {
                        product_id: 12,
                        quantity: ""
                    },
                    13: {
                        product_id: 13,
                        quantity: ""
                    },
                    14: {
                        product_id: 14,
                        quantity: ""
                    },
                    15: {
                        product_id: 15,
                        quantity: ""
                    },
                    16: {
                        product_id: 16,
                        quantity: ""
                    },
                    17: {
                        product_id: 17,
                        quantity: ""
                    },
                    18: {
                        product_id: 18,
                        quantity: ""
                    },
                    19: {
                        product_id: 19,
                        quantity: ""
                    },
                    20: {
                        product_id: 20,
                        quantity: ""
                    },
                    21: {
                        product_id: 21,
                        quantity: ""
                    },
                    22: {
                        product_id: 22,
                        quantity: ""
                    },
                    23: {
                        product_id: 23,
                        quantity: ""
                    },
                    24: {
                        product_id: 24,
                        quantity: ""
                    },
                    25: {
                        product_id: 25,
                        quantity: ""
                    },
                    26: {
                        product_id: 26,
                        quantity: ""
                    },
                    27: {
                        product_id: 27,
                        quantity: ""
                    },
                    28: {
                        product_id: 28,
                        quantity: ""
                    },
                    29: {
                        product_id: 29,
                        quantity: ""
                    },
                    30: {
                        product_id: 30,
                        quantity: ""
                    },
                    31: {
                        product_id: 31,
                        quantity: ""
                    },
                    32: {
                        product_id: 32,
                        quantity: ""
                    }

                }
			},
			districts: [],
            states: []
		};
	},
    validations: {
        value: {
            your_school_name: {
                required,
            },
			school_address: {
				required,
			},
			created_by_name: {
				required,
			},
			created_by_email: {
				required,
			},
			created_by_phone: {
				required,
			},
			free_rate_and_reduce_lunch_rate: {
				required,
			},
			number_students: {
				required,
			},
			current_fulltime_nurses: {
				required,
			},
			existing_nurses_offices: {
				required,
			},
			offices_required_notification: {
				required,
			},
			stadiums_or_events_areas: {
				required,
			},
			numbers_of_seats: {
				required,
			},
			numbers_of_events_per_year: {
				required
			}
}
    },
	mounted() {
		service.getState().then((response) => {
			this.states = response.data.data;
		});
	},
    watch: {
        state_id: function(newVal){
            this.value.state_id = newVal;
            this.getDistrictsByState();
        }
    },
	methods: {
		onComplete() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				this.$alert("Please fill all the required information.",
				"ERROR",
				"error"
				);
			} else {
                this.processEmptyValuesInQuantity();

				service.saveForm(this.value)
					.then((response) => {
						if (response.status === 201) {
							router.push({ name: "success" });
						}
					})
					.catch((error) => {
						this.$alert("We are unable to save your order.",
							"ERROR",
							"error"
						);
					});
			}
		},
		processEmptyValuesInQuantity() {
			let dataToSend = JSON.stringify(
				this.value.details,
				function (key, value) {
					return value === "" ? 0 : value;
				}
			);
			this.value.details = JSON.parse(dataToSend);
		},
        getDistrictsByState(){
            service.getDistrictByState(this.value.state_id).then((response) => {
                this.districts = response.data.data;
            });
        }
	},
};
</script>
<style>
.form-label {
	margin: 15px 10px 10px;
	height: 35px;
	padding: 5px 5px 6px 10px;
	color: white;
	font-weight: bold;
	background-color: #303f9f;
	border-radius: 5px;
}

.content-text {
	font-family: ubuntu;
	position: relative;
	top: 10px;
	width: 100%;
	font-size: 30px;
	line-height: 2em;
	margin-bottom: 60px;
	text-align: center;
}

.content-text-left {
	text-align: left;
}

.content-text-bold {
	font-size: 30px;
	font-weight: 700;
	color: #3749ab;
}

.required {
	color: red;
	font-weight: bold;
	margin-right: 10px;
}

.error {
	color: crimson;
	font-weight: 600;
}

.wizard-card-footer{
    padding-left: 33px !important;
    padding-right: 33px !important;
}

.subscribe-line.subscribe-line-image:after{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    background-color: transparent;
}


</style>
